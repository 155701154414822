(function($, OWX) {

  (function(CE) {

    CE.General = {

      init: function() {
        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      bindUI: function() {

        var that = this;

        $('body').on('click', '.blog-item .readmore', function(e){
          var $content=$(this).closest('.blog-item').find('.blog-content2');
          that.openBlogContent($content[0]);
        });

        $('body').on('click','.menu .owx-pagination a', function(e){
          e.preventDefault();
          $.ajax({
             url: $(this).attr('href'),
             dataType: 'html',
             type: 'get'
          }).done(function(html){  
            $('.blog-list').html($(html).find('.blog-list').html());       
          });
        });










        // on browser back or forward button click reload page content
        window.addEventListener('popstate', function (event) {

          $.ajax({
             url: document.location,
             dataType: 'html',
             type: 'get'
          }).done(function(html){



            $('.menu-container').replaceWith($(html).find('.menu-container'));


          });

        });



      },

      openBlogContent: function(element){
        // get the height of the element's inner content, regardless of its actual size
        var sectionHeight = element.scrollHeight;
        
        // have the element transition to the height of its inner content
        element.style.height = sectionHeight + 'px';

        $(element).closest('.blog-item').removeClass('closed');

        // when the next css transition finishes (which should be the one we just triggered)
        element.addEventListener('transitionend', function(e) {
          // remove this event listener so it only gets triggered once
          element.removeEventListener('transitionend', arguments.callee);
          
          // remove "height" from the element's inline styles, so it can return to its initial value
          element.style.height = null;
        });
        
        // mark the section as "currently not collapsed"
        element.setAttribute('data-collapsed', 'false');
      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);
