(function($, OWX) {
  (function(CE) {

    CE.Menu = {

      init: function() {
        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      bindUI: function() {

        var that = this;


        /* coorect for scrollbar width */

        var scrollbar_width=0;
        if($('.menu-scroller').length>0){
          scrollbar_width=$('.menu-scroller')[0].offsetWidth - $('.menu-scroller')[0].clientWidth;
        }
        if(scrollbar_width>0){
          var node = document.createElement('style');
          node.innerHTML = '.menu-content,.burger-menu-wrapper-content{margin-right:-'+scrollbar_width+'px;}';
          document.body.appendChild(node);
        }


        $('body').on('click','.menu-content a', function(e){
			if($(this).closest('.quali-main-content').length>0){
				return;
			}

          if($(this).closest('.content-wrapper').length===0 && $(this).closest('.blog-content').length===0){
            if(!$(this).hasClass('link_original')){
              e.preventDefault();
              var $menu_col=$(this).closest('.menu-col');
              var level=$menu_col.data('level');
              var $menu_content=$(this).closest('.menu-content');

              

              var query_string='';
              if($(this).data('filter-id')){
                // is filter item


                $(this).closest('li').toggleClass('current_page_item');

                var $filters_li=$menu_content.find('li.current_page_item');
                if($(this).hasClass('filter_all')){
                  if($(this).closest('li').hasClass('current_page_item')){
                    $menu_content.find('li').removeClass('current_page_item');
                    $(this).closest('li').addClass('current_page_item');
                  }
                }else{

                  if($(this).closest('li').hasClass('current_page_item')){
                    $menu_content.find('li a.filter_all').closest('li').removeClass('current_page_item');

                    // find all active filter items
                    var filter_exclude=''+$(this).data('filter-exclude');
                    filter_exclude=filter_exclude.split(',');


/*
                    // deactivate all other items if one active item matches exclude list
                    if($filters_li.length>0 && filter_exclude.length>0){
                      var filter_id;
                      var $that=$(this);
                      $filters_li.each(function(index,element){
                        filter_id=$(element).find('a').data('filter-id');

                        if(filter_exclude.indexOf(''+filter_id)>-1){
                          if($that.data('filter-id')!=filter_id && $(element).hasClass('current_page_item')){
                            $menu_content.find('li').removeClass('current_page_item');
                            $that.closest('li').addClass('current_page_item');
                            //break;

                            // recalculate active filters
                            $filters_li=$menu_content.find('li.current_page_item');
                            return false;
                          }
                        }
                      });
                    }

                    */

                    // deactivate all items fromn exclude list
                    if($filters_li.length>0 && filter_exclude.length>0){
                      var exclude_id;
                      for(var i=0, ii=filter_exclude.length; i<ii; i++ ){
                        exclude_id=filter_exclude[i];
                        $('a[data-filter-id="'+exclude_id+'"]').closest('li').removeClass('current_page_item');
                      }
                      // recalculate active filters
                      $filters_li=$menu_content.find('li.current_page_item');
                    }

                  }
                }
                

                if($filters_li.length>0){

                  var filters_arr=[];
                  $filters_li.each(function(index,element){
                    filters_arr.push($(element).find('a').data('filter-id'));
                  });
                  //console.log(filters_arr);
                  query_string='?filter='+filters_arr.join('-');
                  if($(this).hasClass('filter_all')){
                    query_string="";
                  }
                  that.openMenu($(this).attr('href')+query_string, level+1);
                }else{
                  that.closeHigherLevelsThan(level);
                }

              }else{
                $menu_content.find('li').removeClass('current_page_item');
                $(this).closest('li').addClass('current_page_item');
                that.openMenu($(this).attr('href')+query_string, level+1);
              }
              
            }
          }
		});
		
		$('body').on('click','.menu-close-icon', function(e){
			CE.Menu.closeHigherLevelsThan(1);
		});

        $('body').on('click','.mainmenu a', function(e){
          e.preventDefault();

          $('.mainmenu li').removeClass('current_page_item').removeClass('current-page-ancestor');
          $(this).closest('li').addClass('current_page_item');

          that.centerMainMenu($(this));
		  that.openMenu($(this).attr('href'), 2);
		  
		  setTimeout(function(){
			$('.news-panel-headline').remove();
			$('.menu-close-icon').remove();
		  }, 500);

		});
		
		console.log('ss');

        $('body').on('click','li.panel a', function(e){
			e.preventDefault();
  console.log('ss');
	//		$('.mainmenu li').removeClass('current_page_item').removeClass('current-page-ancestor');
			
  
	//		that.centerMainMenu($(this));
	$('.news-panel').remove();
			that.closeBurgerMenu();
			that.openMenu($(this).attr('href'), 2);
  
		  });



        $('body').on('click','.burger-menu-icon', function(e){
          if($('.burger-menu-icon').hasClass('open')){
            that.closeBurgerMenu();
          }else{
            that.openBurgerMenu();
          }
        });

        $('body').on('click','a.home-link',function(e){news-panel-headline
          if($(this).closest('.page-type-content').length===0){
            e.preventDefault();
            that.closeHigherLevelsThan(1);
          }
          history.pushState('', "", '/');
        });

        $('body').on('click','.menu-container',function(e){
          if($(e.target).hasClass('menu-container')){
            e.preventDefault();
            that.closeHigherLevelsThan(1);
            history.pushState('', "", '/');
          }
        });   
           


        $('body').on('mouseenter','.menu-level-last a',function(e){
          var count=$(this).data('count');
          if(count>0){
            $('.badge-count').html(count);
          }
        });
        $('body').on('mouseleave','.menu-level-last a',function(e){
          if($('.badge-count').data('fixed') && $('.badge-count').data('fixed')>0){
            $('.badge-count').html($('.badge-count').data('fixed'));
          }else{
            $('.badge-count').empty();
          }
        });
        $('body').on('click','.menu-level-last a',function(e){
          var count=$(this).data('count');
          if(count>0){
            $('.badge-count').html(count);
            $('.badge-count').data('fixed',count);
          }
		});
        $('body').on('click','.menu-container',function(e){
			var $target=$(e.target);
  
			if($target.closest('#quali-dozent-popup').length>0 || $target.hasClass('quali-item-dozent-link')){
			  return;
			}
			if($target.closest('.quali-booking-info').length>0 || $target.hasClass('quali-booking-info')){
				return;
			  }
			$('#quali-dozent-popup').removeClass('open');
		});


        /*
        * Handle Panel Close and Burger Close, if User clicks somewhere
        */
        $('body').on('click','.menu-container',function(e){
          var $target=$(e.target);

          if(
            $target.hasClass('burger-menu-icon')
            || $target.closest('.burger-menu-icon').length>0
            || $target.hasClass('burger-menu-wrapper')
            || $target.closest('.burger-menu-wrapper').length>0
          ){
            return;
          }

          that.closeBurgerMenu();

          if($target.closest('.mainmenu').length>0 && e.target.nodeName==='A'){
            return;
          }

          if($target.closest('.menu-col').length===0){
            that.closeHigherLevelsThan(1);
            that.resetMainMenu();
            return;
          }

          if(e.target.nodeName!=='A'){
            if($target.closest('.menu-level-4').length>0){
              that.closeHigherLevelsThan(4);
              return;
            }
            if($target.closest('.menu-level-3').length>0){
              that.closeHigherLevelsThan(3);
              return;
            }
            if($target.closest('.menu-level-2').length>0){
              that.closeHigherLevelsThan(2);
              return;
            }
          }

		});
		

		$('body').on('click','a.cat-filter', function(e){
			e.preventDefault();
			$('a.cat-filter').removeClass('current-cat');
			$(this).addClass('current-cat');
			var cat=$(this).attr('data-cat');
			$('.quali-item').removeClass('active-cat');
			$('.quali-item.'+cat).addClass('active-cat');
		});

		$('body').on('click','a.quali-item-dozent-link', function(e){
			e.preventDefault();
			var dozent_id=$(this).attr('data-id');
			var $dozent=$('.quali-item[data-id="'+dozent_id+'"] .quali-item-content').clone();
			$dozent.find('label').removeAttr('for');
			$dozent.find('input').remove();
			$dozent.find('.accordeon-control').remove();

			$('#quali-dozent-popup').addClass('open');
			$('#quali-dozent-popup .menu-content').empty().append($dozent);
		});

		$('body').on('click','a.quali-booking-info', function(e){
			e.preventDefault();
			/*
			var dozent_id=$(this).attr('data-id');
			var $dozent=$('.quali-item[data-id="'+dozent_id+'"] .quali-item-content').clone();
			$dozent.find('label').removeAttr('for');
			$dozent.find('input').remove();
			$dozent.find('.accordeon-control').remove();
			*/

			var $content=$('#quali-booking-info .quali-item-content').clone();
			console.log($content);

			$('#quali-dozent-popup').addClass('open');
			$('#quali-dozent-popup .menu-content').empty().append($content);
		});


		$('body').on('click','.quali-months a', function(e){
			e.preventDefault();
			var id=$(this).attr('data-month');

			var myElement = document.getElementById(id);
			var topPos = myElement.offsetTop;

			$('.quali-body-right .menu-scroller').stop().animate({
				'scrollTop': topPos
			   }, 700, 'swing');


		});


		


      },

      openBurgerMenu: function(){
        $('.burger-menu-icon').removeClass('closed').addClass('open');
        $('.burger-menu-wrapper').addClass('open');
      },
      closeBurgerMenu: function(){
        if(!$('.burger-menu-wrapper').hasClass('open')){
          return;
        } 
        $('.burger-menu-icon').removeClass('open').addClass('closed');
        $('.burger-menu-wrapper').removeClass('open');
      },

      resetMainMenu: function(){
        $('.mainmenu').css('margin-bottom','0px').css('margin-top','0px');
        $('.mainmenu li').removeClass('current_page_item').removeClass('.current-page-ancestor');
        history.pushState('', "", '/');
      },


      centerMainMenu: function($a){
        var $links=$('.mainmenu li:not(.dummy) a');
        var menu_count=$links.length;
        var index=$links.index($a);
        var itemsBefore=index;
        var itemsAfter=menu_count-index-1;
        var itemsBeforeAndAfter=Math.max(itemsBefore,itemsAfter);
        var itemsToInsert=Math.abs(itemsAfter-itemsBefore);

        var lineHeight=1.3;


        if(itemsBefore>itemsAfter){
            $('.mainmenu').css('margin-top','0px');
            $('.mainmenu').css('margin-bottom', itemsToInsert*lineHeight+'em');
        }else if(itemsBefore<itemsAfter){
          $('.mainmenu').css('margin-bottom','0px');
          $('.mainmenu').css('margin-top', itemsToInsert*lineHeight+'em');
        }else{
          $('.mainmenu').css('margin-bottom','0px');
          $('.mainmenu').css('margin-top', '0px');
        }
        console.log(index);
      },

      closeHigherLevelsThan: function(level){

		setTimeout(function(){
			$('.news-panel').remove();
		}, 500);

        $('.menu-col[data-level="'+level+'"] li').removeClass('current_page_item').removeClass('current-page-ancestor');
        // close all menus with higher levels
        $('.menu-col').each(function(index){
          if($(this).data('level')>level){
            $(this).addClass($(this).data('to')).removeClass('open');
          }
        });
      },

      openMenu: function(url, level){
		console.log(level);
		

        this.closeHigherLevelsThan(level);

        // load page dynamically
        $.ajax({
           url: url,
           dataType: 'html',
           type: 'get'
        }).done(function(html){
          var $new_menu=$(html).find('.menu-col[data-level="'+level+'"]');
          var $old_menu=$('.menu-col[data-level="'+level+'"]');
          var was_open=$old_menu.hasClass('open');

          var title = html.match("<title>(.*?)</title>")[1];

          history.pushState('', title, url);
          
          document.title=title;

          if($old_menu.length>0 && was_open){
            // menu-container exists in current page -> replace submenu

            

            if($new_menu.length>0){
              

              $old_menu.attr('class',$new_menu.attr('class'));
              if(was_open){
                $old_menu.addClass('open');
                $old_menu.find('.badge-count').empty().data('fixed',0);
              }
              $old_menu.attr('style',$new_menu.attr('style'));
              $old_menu.find('.menu-content').html($new_menu.find('.menu-content').html());
              $old_menu.attr('data-from',$new_menu.attr('data-from'));
			  $old_menu.attr('data-to',$new_menu.attr('data-to'));
			  
            }else{
              $old_menu.find('.menu-content').empty();
            }
            setTimeout(function(){
                $old_menu.addClass('open');
			},1000);
			

          }else{
            // menu-container does not exist in current page or is closed -> append new submenu

            $('.menu-col[data-level="'+level+'"]').remove();

            $new_menu.addClass($new_menu.data('from'));

			$('.menu-container').append($new_menu);

console.log($new_menu);			

            setTimeout(function(){
              $new_menu.removeClass($new_menu.data('from'));
              setTimeout(function(){
				console.log('j');
                $new_menu.addClass('open');
              },1000);
			},100);
			
			
          }     
          

          if($new_menu.hasClass('menu-level-last')){
            OWX.CE.Slider.init();
          }     
        });







      },

      unbindUI: function() {

        var that = this;


      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);
