(function($, OWX) {

  (function(CE) {

    CE.Slider = {

      init: function() {
        this.bindUI();
      },

      deinit: function() {
        this.unbindUI();
      },

      bindUI: function() {

        var that = this;
        var slider;

        $('.owx-gallery').on('init', function(event, slick){
          if(slick.$slides){
            var caption='';
            var $caption=$(slick.$slides.get(0)).find('.owx-gallery-item-caption');
            if($caption.length>0){
              caption=$caption.html();            
            }
            $blogitem=$(this).closest('.blog-item');
            $blogitem.find('.blog-image-caption').html(caption);

          }
        });

        $('.owx-gallery').on('beforeChange', function(event, slick, currentSlide, nextSlide){
          if(slick.$slides){
            $blogitem=$(this).closest('.blog-item');
            $blogitem.find('.blog-image-caption').addClass('transitioning');
          }
        });
        
        $('.owx-gallery').on('afterChange', function(event, slick, currentSlide){
          var caption='';
          var $caption=$(slick.$slides.get(currentSlide)).find('.owx-gallery-item-caption');
          if($caption.length>0){
            caption=$caption.html();            
          }
          $blogitem=$(this).closest('.blog-item');
          $blogitem.find('.blog-image-caption').html(caption);          
          $blogitem.find('.blog-image-caption').removeClass('transitioning');
        });


        $slider=$('.owx-gallery').slick({
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
          prevArrow: '<div class="slick-nav slick-prev"></div>',
          nextArrow: '<div class="slick-nav slick-next"></div>',
          dots: false,
          customPaging: function (slick, index) {
            return '<span class="dot dot-' + index + '"></span>';
          },
        });

  setInterval(function(){
      $('.owx-gallery').slick('setPosition');
  },100);
  setInterval(function(){
      $('.owx-gallery').slick('setPosition');
  },500);
    setInterval(function(){
      $('.owx-gallery').slick('setPosition');
  },1000);


      },

      unbindUI: function() {

        var that = this;

        $('.owx-gallery.owx-layout-default.slick-initialized').slick('unslick');

      }

    };

  })(OWX.CE = OWX.CE || {});

})(jQuery, OWX);
